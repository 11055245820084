import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.5, color: '#595959' }}>
        <h1 style={{ fontSize: '2rem', fontWeight: 600, marginBottom: '1.5rem' }}>Privacy Policy</h1>
        <div>
          <p>
            <strong>1. Data Collection:</strong> We collect information you provide when booking airport transfer services, including your name, contact number, flight details, and payment information.
          </p>
          <p>
            <strong>2. Data Usage:</strong> The collected information is used to process your booking, send confirmations, and provide the requested airport transfer services.
          </p>
          <p>
            <strong>3. Data Security:</strong> We implement appropriate security measures to protect your personal information from unauthorized access.
          </p>
          <p>
            <strong>4. Data Sharing:</strong> Your information is only shared with parties involved in providing the transfer service (such as drivers) and will not be sold or shared with third parties for marketing purposes.
          </p>
          <p>
            <strong>5. Data Storage:</strong> Your data is stored for as long as necessary to provide services and comply with applicable legal requirements.
          </p>
          <p>
            <strong>6. User Rights:</strong> You have the right to access, correct, or request deletion of your personal data.
          </p>
          <p>
            <strong>7. Cookies:</strong> Our website uses cookies to enhance user experience and analyze site usage.
          </p>
          <p>
            <strong>8. Policy Changes:</strong> This privacy policy may be updated from time to time. Changes will be announced on our website.
          </p>
          <p>
            <strong>9. Contact:</strong> For questions about this privacy policy, please contact us at account@baliguided.com.
          </p>
          <p>
            <strong>10. Consent:</strong> By using our services, you agree to this privacy policy.
          </p>
          <p>
            <strong>11. Data Protection:</strong> We take all reasonable steps to ensure your data is treated securely and in accordance with this privacy policy.
          </p>
          <p>
            <strong>12. International Transfers:</strong> Your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ.
          </p>
          <p>
            <strong>13. Children's Privacy:</strong> Our services are not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under 13.
          </p>
          <p>
            <strong>14. Third-Party Links:</strong> Our website may contain links to other sites that are not operated by us. We strongly advise you to review the Privacy Policy of every site you visit.
          </p>
          <p>
            <strong>15. Legal Basis:</strong> We process your personal information based on your consent and/or to fulfill our contractual obligations to you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
