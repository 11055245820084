import http from "../utils/httpClient";

const getMe = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('Token not found');
    return null; // Kembalikan null jika token tidak ada
  }

  try {
    const response = await http.get('/users/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Get my data error:', error);
    throw error;
  }
};

const updateMe = async (data) => {
  try {
    const response = await http.patch("/users/me", data);
    return response.data.payload;
  } catch (error) {
    console.error("Update my data error:", error);
    throw new Error(error.data.message);
  }
};

const updateMyProfilePicture = async (data) => {
  try {
    const response = await http.patch("/users/me/profile-picture", data);
    return response.data.payload;
  } catch (error) {
    console.error("Update my profile picture error:", error);
    throw new Error(error.data.message);
  }
};

const deleteMe = async () => {
  try {
    const response = await http.delete("/users/me");
    return response.data.payload;
  } catch (error) {
    console.error("Delete my data error:", error);
    throw new Error(error.data.message);
  }
};

const userService = {
  getMe,
  updateMe,
  updateMyProfilePicture,
  deleteMe,
};

export default userService;
