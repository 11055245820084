import React, { FC, useState, useEffect } from "react";
import authService from "services/authService";
import Input from "shared/Input/Input";
import { Alert } from "shared/Peringatan/Peringatan";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const VerifEmailMessage: FC = () => {
    const [email, setEmail] = useState<string>(""); 
    const [inputEmail, setInputEmail] = useState<string>(""); 
    const [message, setMessage] = useState<string>("");
    const [typeMessage, setTypeMessage] = useState<"success" | "error" | "default" | "warning" | "info">("default");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const savedEmail = localStorage.getItem("registeredEmail");
        if (savedEmail) {
            setEmail(savedEmail);
            setInputEmail(savedEmail); 
        }
    }, []);

    const handleSendEmail = async () => {
        if (!inputEmail) {
            setMessage("Please enter your email.");
            setTypeMessage("error");
            return;
        }

        try {
            setLoading(true);
            const response = await authService.resendVerificationEmail({ email: inputEmail });
            setEmail(inputEmail); 
            setMessage(response.message);
            setTypeMessage("success");
        } catch (error: any) {
            setMessage(error.message);
            setTypeMessage("error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center my-14">
            <div className="text-center">
                <h2 className="text-3xl font-semibold">Check your email for Verification</h2>
            </div>
            <div className="mt-20 w-full md:w-[500px] text-center">
                {message && <Alert type={typeMessage} close={() => setMessage("")}>{message}</Alert>}
                <p className="mb-2">
                    {email
                        ? `Email was sent to ${email}. Resend email verification:`
                        : "Please enter your email to resend verification."}
                </p>
                <Input
                    placeholder="Your email"
                    type="email"
                    value={inputEmail}
                    onChange={(e) => setInputEmail(e.target.value)}
                />
            </div>
            <div className="mt-8">
                <ButtonPrimary
                    onClick={handleSendEmail}
                    type="button"
                    disabled={loading}
                >
                    {loading ? (
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                        </div>
                    ) : "Resend"}
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default VerifEmailMessage;
