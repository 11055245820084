import axios from 'axios';
import httpClient from '../utils/httpClient';


const API_BASE_URL = `${process.env.REACT_APP_BACKEND_LINK}/airport-transfer` // Change this to the correct API path for airport transfers

const getAuthHeaders = () => {
  const token = localStorage.getItem('token'); 
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const airportTransferService = {
  createBooking: async (bookingData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}`, bookingData, {
        ...getAuthHeaders(),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.payload;
    } catch (error) {
      console.error('Create booking error:', error);
      throw new Error(error.message);
    }
  },

  // Fungsi untuk konfirmasi booking
  confirmBooking: async (cartData) => {
    try {
      const response = await httpClient.post('/airport-transfer/send-booking-email', cartData);
      return response.data;
    } catch (error) {
      console.error('Confirm booking error:', error);
      throw new Error(error.message);
    }
  },

  getBookingById: async (bookingId) => {
    try {
        console.log('Calling getBookingById with:', bookingId);
        const response = await httpClient.get(`/airport-transfer/booking-id/${bookingId}`);
        console.log('Complete response from backend:', response.data); // Log complete response
        
        if (response.data && response.data.payload) {
            console.log('Extracted payload:', response.data.payload);
            return response.data.payload;
        } else {
            console.log('No payload in response:', response.data);
            return null;
        }
    } catch (error) {
        console.error('Get booking error:', error);
        throw error;
    }
  }
};

  




export default airportTransferService;
