import React from 'react';
import { Dialog } from '@headlessui/react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface LoginModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ show, onClose, onSuccess }) => {
  return (
    <Dialog open={show} onClose={onClose} className="fixed z-50 inset-0">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Panel className="bg-white p-6 rounded-lg shadow-xl">
          <Dialog.Title className="text-xl font-bold">Login</Dialog.Title>
          {/* Add your login form here */}
          <div className="mt-4">
            <ButtonPrimary onClick={onSuccess}>Login</ButtonPrimary>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LoginModal;