import React, { useEffect, useState } from 'react';
import reviewService from 'services/reviewService.js';
import CommentListing from 'components/Komentar/CommentListing';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';

const UserReviews = () => {
    const [reviews, setReviews] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { isAuthenticated, userId } = useAuth();

    useEffect(() => {
        const fetchUserReviews = async () => {
            try {
                const response = await reviewService.getUserReviews();
                console.log('API Response:', response);
                setReviews(response || []);
            } catch (error) {
                console.error('Error fetching user reviews:', error);
                setReviews([]);
            } finally {
                setIsLoading(false);
            }
        };

        if (isAuthenticated && userId) {
            fetchUserReviews();
        } else {
            setIsLoading(false);
        }
    }, [isAuthenticated, userId]);

    if (!isAuthenticated) {
        return (
            <div className="container mx-auto py-8">
                <p className="text-center text-neutral-500">
                    Please login to view your reviews
                </p>
            </div>
        );
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto py-8">
            <h1 className="text-3xl font-bold mb-6">My Reviews</h1>
            <div className="space-y-6">
                {reviews && reviews.length > 0 ? (
                    reviews.map((review, index) => (
                        <div key={index} className="border rounded-lg p-6 bg-white shadow-sm">
                            <div className="mb-4">
                                <Link 
                                    to={`/experiences/details/${review.experience?.slug}`}
                                    className="text-xl font-semibold text-primary-600 hover:text-primary-700"
                                >
                                    Reviewed on: {review.experience?.title}
                                </Link>
                                <div className="text-sm text-gray-500 mt-1">
                                    {new Date(review.created_at).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </div>
                            </div>
                            <CommentListing review={review} showExperience={false} />
                        </div>
                    ))
                ) : (
                    <p className="text-neutral-500">You haven't written any reviews yet.</p>
                )}
            </div>
        </div>
    );
};

export default UserReviews; 