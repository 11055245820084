import React, { FC, useEffect, useState } from "react";
import Pagination from "shared/Pagination/Pagination";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/CardTour/ExperiencesCard";
import experienceService from 'services/experienceService.js';
import { ExperiencesDataType } from "data/types";
import { useLocation } from "react-router-dom"; // Gunakan useLocation untuk mendapatkan query dari URL

export interface SectionGridFilterCardSearchProps {
  className?: string;
}

const SectionGridFilterCardSearch: FC<SectionGridFilterCardSearchProps> = ({
  className = "",
}) => {
  const [experiences, setExperiences] = useState<ExperiencesDataType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation(); // Mendapatkan URL query string dari URL

  // Fungsi untuk mendapatkan query dari URL
  const getSearchQuery = () => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("query");
    console.log("searchQuery dari URL:", searchQuery); // Tambahkan log untuk debugging
    return searchQuery;
  };

  const fetchExperiences = async (searchQuery: string | null, page: number = 1) => {
    if (!searchQuery) {
      console.error("No search query found.");
      return;
    }

    try {
      const response = await experienceService.searchExperiences(searchQuery);  // Ganti menjadi searchExperiences
      if (response) {
        setExperiences(response.payload); // Pastikan payload sesuai dengan struktur yang diterima dari API
        setTotalPages(response.meta.totalPages);
      } else {
        console.error('No payload found:', response);
      }
    } catch (error) {
      console.error('Failed to fetch experiences:', error);
    }
  };

  useEffect(() => {
    const searchQuery = getSearchQuery(); // Ambil nilai searchQuery dari URL
    if (searchQuery) {
      fetchExperiences(searchQuery, currentPage); // Panggil API dengan query string yang benar
    } else {
      console.error("No search query found.");
    }
  }, [currentPage, location.search]); // Jalankan ulang jika currentPage atau query berubah

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Experiences in Bali"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            100+ Experiences
            <span className="mx-2">·</span>
            Start From 10$
            <span className="mx-2">·</span>Solo Traveler Welcomed
          </span>
        }
      />
      {experiences.length === 0 ? (
        <p>No experiences found for "{getSearchQuery()}".</p> // Tampilkan pesan jika tidak ada hasil pencarian
      ) : (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          {experiences.map((experience) => (
            <ExperiencesCard key={experience.id} data={experience} />
          ))}
        </div>
      )}
      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default SectionGridFilterCardSearch;
