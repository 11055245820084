import React, { FC } from "react";
import GallerySlider from "components/SliderGambar/GallerySlider";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/ButtonLike/ButtonLike";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";

export interface ExperiencesCardProps {
  className?: string;
  ratioClass?: string;
  data: ExperiencesDataType;
  size?: "default" | "small";
}

const ExperiencesCard: FC<ExperiencesCardProps> = ({
  size = "default",
  className = "",
  data,
  ratioClass = "aspect-w-3 aspect-h-3",
}) => {
  if (!data) {
    return null;
  }

  const {
    experience_medias = [],
    location = '',
    title = '',
    id = '',
    slug = '',
    price = 0,
    experience_type_id = 1,
    reviews = [],
  } = data;

  console.log('Reviews:', reviews); // Tambahkan log ini untuk memeriksa data reviews

  const galleryImgs = experience_medias.map(media => media.media.path);

  // Calculate the number of reviews
  const reviewCount = Array.isArray(reviews) ? reviews.length : 0;

  // Calculate average rating
  const averageRating = reviewCount > 0 
    ? reviews.reduce((acc: number, review: { rating: number }) => acc + review.rating, 0) / reviewCount 
    : 0;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
        <Link to={`/experiences/details/${slug}`}>
          <GallerySlider
            uniqueID={`ExperiencesCard_${id}`}
            ratioClass={ratioClass}
            galleryImgs={galleryImgs}
            href={`/experiences/details/${slug}`}
          />
          <BtnLikeIcon isLiked={false} className="absolute right-3 top-3" />
          {false && <SaleOffBadge className="absolute left-3 top-3" />}
        </Link>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">{location}</span>
          </div>

          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-base" : "text-base"
              }`}
            >
              <Link to={`/experiences/details/${slug}`} className="line-clamp-1">
                {title}
              </Link>
            </h2>
          </div>
        </div>
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            IDR {price.toLocaleString("id-ID")}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                {experience_type_id === 1 ? "/person" : "/group"}
              </span>
            )}
          </span>
          <div className="flex items-center space-x-2">
            <StartRating reviewCount={reviewCount} point={averageRating} />
            {/* Tambahkan jumlah review di sini */}
            {reviewCount > 0 && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400">
                ({reviewCount} review{reviewCount > 1 ? "s" : ""})
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={`/experiences/details/${slug}`}>{renderContent()}</Link>
    </div>
  );
};

export default ExperiencesCard;
