import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";
import wishlistService from "services/wishlistService";

interface LikeSaveBtnsProps {
  experienceId: string;
  slug: string;
}

const LikeSaveBtns: React.FC<LikeSaveBtnsProps> = ({ experienceId, slug }) => {
  const [isInWishlist, setIsInWishlist] = useState(false);
  const { isAuthenticated, userId } = useAuth();

  useEffect(() => {
    if (isAuthenticated && experienceId) {
      checkWishlistStatus();
    }
  }, [isAuthenticated, experienceId]);

  const checkWishlistStatus = async () => {
    try {
      const response = await wishlistService.checkWishlist(experienceId);
      setIsInWishlist(response.payload);
    } catch (error) {
      console.error("Error checking wishlist status:", error);
    }
  };

  const handleWishlistClick = async () => {
    if (!isAuthenticated) {
      // Redirect to login
      window.location.href = "/login";
      return;
    }

    try {
      if (isInWishlist) {
        await wishlistService.removeFromWishlist(experienceId);
        setIsInWishlist(false);
      } else {
        await wishlistService.addToWishlist(experienceId);
        setIsInWishlist(true);
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
    }
  };

  const handleShareClick = () => {
    const currentUrl = window.location.origin + "/experiences/details/" + slug;
    
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this experience",
          url: currentUrl,
        })
        .then(() => {
          console.log("Sharing successful");
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log("Share canceled by the user");
          } else {
            console.error("Error sharing:", error);
          }
        });
    } else {
      // Fallback untuk browser yang tidak mendukung navigator.share
      navigator.clipboard.writeText(currentUrl).then(() => {
        alert("Link copied to clipboard!");
      });
    }
  };

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span
          className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
          onClick={handleShareClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span>
        <span
          className={`py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer ${
            isInWishlist ? "text-red-500" : ""
          }`}
          onClick={handleWishlistClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 ${isInWishlist ? "scale-125" : ""}`}
            fill={isInWishlist ? "currentColor" : "none"}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">
            {isInWishlist ? "Wishlisted" : "Wishlist"}
          </span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
