import React from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const PLACES = [
  { name: 'Ubud', image: '/images/places/ubud.jpg' },
  { name: 'Uluwatu', image: '/images/places/uluwatu.jpg' },
  { name: 'Nusa Penida', image: '/images/places/nusa-penida.jpg' },
  { name: 'Canggu', image: '/images/places/canggu.jpg' },
  { name: 'Seminyak', image: '/images/places/seminyak.jpg' },
  { name: 'Kuta', image: '/images/places/kuta.jpg' },
  { name: 'Jimbaran', image: '/images/places/jimbaran.jpg' },
  { name: 'Sanur', image: '/images/places/sanur.jpg' },
];

const SectionByPlaces = () => {
  const navigate = useNavigate();
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainer.current) {
      const scrollAmount = 300;
      scrollContainer.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handlePlaceClick = (placeName: string) => {
    navigate(`/experiences/places/${placeName.toLowerCase()}`);
  };

  return (
    <div className="relative py-8">
      <h2 className="text-2xl font-semibold mb-6">Popular Places in Bali</h2>
      
      <div className="relative group">
        <button 
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 w-10 h-10 bg-white rounded-full shadow-md flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </button>

        <div 
          ref={scrollContainer}
          className="flex overflow-x-auto gap-4 pb-4 scrollbar-hide"
        >
          {PLACES.map((place, index) => (
            <div 
              key={index}
              className="flex-none w-48 cursor-pointer hover:transform hover:scale-105 transition-transform duration-200"
              onClick={() => handlePlaceClick(place.name)}
            >
              <div className="relative rounded-xl overflow-hidden aspect-[4/3]">
                <img 
                  src={place.image} 
                  alt={place.name}
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-20" />
                <h3 className="absolute bottom-3 left-3 text-white font-medium text-lg">
                  {place.name}
                </h3>
              </div>
            </div>
          ))}
        </div>

        <button 
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 w-10 h-10 bg-white rounded-full shadow-md flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default SectionByPlaces; 