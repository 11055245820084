import { create } from 'zustand';
import userService from 'services/userService';

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} email
 * @property {string} full_name
 * @property {string} date_of_birth
 * @property {string} country
 * @property {string} phone_number
 * @property {{path: string}} [profile_picture]
 */

const useUserStore = create((set) => ({
  user: null,
  setUser: (userData) => set({ user: userData }),
  clearUser: () => set({ user: null }),
  fetchUser: async () => {
    try {
      const response = await userService.getMe();
      set({ user: response.payload });
    } catch (error) {
      console.error('Error fetching user:', error);
      set({ user: null });
    }
  },
}));

export const useUserStoreActions = () => {
  const { fetchUser, setUser, clearUser } = useUserStore();
  return { fetchUser, setUser, clearUser };
};

export default useUserStore;