import { Tab } from "@headlessui/react";
import ExperiencesCard from "components/CardTour/ExperiencesCard";
import React, { Fragment, useState, useEffect } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import wishlistService from "../../services/wishlistService";
import { useAuth } from "../../hooks/useAuth";

const AccountSavelists = () => {
  const [categories] = useState(["Experiences"]);
  const [wishlists, setWishlists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchWishlists = async () => {
      try {
        const response = await wishlistService.getUserWishlists();
        setWishlists(response.payload);
      } catch (error) {
        console.error("Error fetching wishlists:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchWishlists();
    }
  }, [isAuthenticated]);

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Wishlist</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                {isLoading ? (
                  <div>Loading...</div>
                ) : wishlists.length > 0 ? (
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {wishlists.map((item: any) => (
                      <ExperiencesCard 
                        key={item.experience.id} 
                        data={item.experience}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-10">
                    <p>No experiences in your wishlist yet.</p>
                  </div>
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
