import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_LINK;

// Create axios instance with default config
const axiosInstance = axios.create();

// Add request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

class BookingService {
  async getUserBookings() {
    try {
      const response = await axiosInstance.get(`${API_URL}/bookings/user/bookings`);
      console.log('Raw response:', response); // Debug response
      return response.data;
    } catch (error) {
      console.error('Error fetching bookings:', error);
      throw error;
    }
  }

  async getBookingDetails(bookingId) {
    try {
      const response = await axiosInstance.get(`${API_URL}/bookings/${bookingId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createBooking(bookingData) {
    try {
      const response = await axiosInstance.post(`${API_URL}/bookings/create`, bookingData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new BookingService(); 