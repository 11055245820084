import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import CommentListing from "components/Komentar/CommentListing";
import FiveStartIconForRate from "components/BintangLima/BintangLima";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/Wishlist/LikeSaveBtns";
import wishlistService from "services/wishlistService";
import { Helmet } from "react-helmet"; // Import Helmet 
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import StayDatesRangeInput from "./StayDatesRangeInput";
import GuestsInput from "./GuestsInput";
import experienceService from 'services/experienceService.js';
import GallerySlider from "components/SliderGambar/GallerySlider";
import ImageGalleryModal from "./ImageGalleryModal"; // Import modal
import reviewService from "services/reviewService.js"; // Tambahkan .js
import { ClockIcon } from "@heroicons/react/24/outline";
import axios from 'axios';
import { TwMainColor } from "data/types";
import { time } from "console";
import { useAuth } from '../../../hooks/useAuth';

const ListingExperiencesDetail: FC<{}> = () => {
    const navigate = useNavigate();
    const { slug } = useParams<{ slug: string }>();
    const [experience, setExperience] = useState<any>(null);
    const [reviews, setReviews] = useState<any[]>([]);
    const [newReview, setNewReview] = useState({ rating: 0, message: "" });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null); // Single date
    const [galleryImages, setGalleryImages] = useState<string[]>([]);
    const [currency, setCurrency] = useState<"IDR" | "USD" | "EUR">("IDR"); // Set the type explicitly
    const [adultGuests, setAdultGuests] = useState(1); // Default 1 adult guest
    const [childGuests, setChildGuests] = useState(0); // Default 0 child guests    
    const [infantGuests, setInfantGuests] = useState(0); // Default 0 infant guests
    const [guests, setGuests] = useState(1); // Default 1 guest
    const [displayLimit, setDisplayLimit] = useState(5); // Initial display limit for reviews
    const [selectedTime, setSelectedTime] = useState<string>("");
    const [isLoading, setIsLoading] = useState(true); // State untuk loading
    const { isAuthenticated, userId } = useAuth();
    console.log('Auth state:', { isAuthenticated, userId }); // Debugging

    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    useEffect(() => {
        const fetchExperienceAndReviews = async () => {
            try {
                setIsLoading(true);
                // Fetch experience
                const data = await experienceService.getExperienceBySlug(slug);
                setExperience(data);
                setGalleryImages(data.experience_medias.map((media: any) => media.media.path));
                
                // Fetch reviews
                const reviewsData = await reviewService.getReviews(data.id);
                setReviews(reviewsData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (slug) {
            fetchExperienceAndReviews();
        }
    }, [slug]);
    

      // Jika data masih diambil, tampilkan loading state
      if (isLoading || !experience) {
        return <div>Loading...</div>;
    }


    const handleDateChange = (date: Date | null) => {
      console.log("Selected Date:", date); // Log the date to ensure it’s selected
      setSelectedDate(date);
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const time = event.target.value;
        setSelectedTime(time); // Update state dengan waktu yang dipilih
        console.log("Selected Time: ", time); // Cetak waktu yang dipilih ke console (optional)
      };
      
    
    const handleGuestsChange = (totalGuests: number) => {
      setGuests(totalGuests);
    };

    const handleReviewSubmit = async () => {
        console.log('Current auth state:', { isAuthenticated, userId }); // Debugging
        
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (!userId) {
            console.error('No user ID found. Auth state:', { isAuthenticated, userId });
            return;
        }

        try {
            // Pastikan data yang dikirim lengkap
            const reviewData = {
                rating: newReview.rating,
                message: newReview.message,
                experience_id: experience.id,
                user_id: userId  // Tambahkan user_id ke data yang dikirim
            };

            console.log('Submitting review with data:', reviewData); // untuk debugging

            const response = await reviewService.createReview(
                reviewData.experience_id,
                reviewData.rating,
                reviewData.message,
                reviewData.user_id
            );

            // Refresh reviews setelah submit
            const updatedReviews = await reviewService.getReviews(experience.id);
            setReviews(updatedReviews);
            
            // Reset form
            setNewReview({ rating: 0, message: '' });

            // Optional: Tambahkan feedback untuk user
            alert('Review submitted successfully!');
        } catch (error) {
            console.error('Failed to submit review:', error);
            if (axios.isAxiosError(error)) {
                alert(error.response?.data?.error || 'Failed to submit review');
            } else {
                alert('Failed to submit review');
            }
        }
    };

    const getCategoryColor = (typeId: number): TwMainColor => {
        switch (typeId) {
            case 1:
                return 'blue'; // Activities
            case 0:
                return 'green'; // Tour
            default:
                return 'gray'; // Default color
        }
    };

    const handleLoadMoreReviews = () => {
        setDisplayLimit((prevLimit) => prevLimit + 5); // Increase limit by 5
    };

    if (!experience) {
        return <div>Loading...</div>;
    }

    const {
        experience_medias = [],
        experience_type_id = 1, // Default to 1 for Activity
        location = '',
        title = '',
        id = '',
        rating_average = 0,
        price = 0,
        description = '',
        requirement = '',
        includes = '',
        cancellation_policy = '',
        max_participant_count = 0,
        what_to_bring = '',
        host = '',
    } = experience;

    const exchangeRates = {
        USD: 0.000068,
        EUR: 0.000059,
        IDR: 1,
    };

    const convertPrice = (price: number, currency: "IDR" | "USD" | "EUR") => {
        if (currency === "IDR") {
            return price.toLocaleString("id-ID", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
        }
        return price * exchangeRates[currency];
    };

    console.log("experience_pickups:", experience.experience_pickups);



    const convertedPrice = convertPrice(price, currency);

    const calculatePrice = () => {
        if (experience_type_id === 1) {
          // Activity: Calculate for adults and children
          const adultPriceTotal = price * adultGuests;
          const childPriceTotal = experience.has_child_price ? (experience.child_price || 0) * childGuests : 0;
      
          // Total price is the sum of adult and child pricing
          return convertPrice(adultPriceTotal + childPriceTotal, currency);
        } else if (experience_type_id === 2) {
          // Tour: Flat price, no child price consideration
          return convertPrice(price, currency);
        }
      
        // Default case, just return the base price
        return convertPrice(price, currency);
      };
      

    const totalPrice = calculatePrice(); // Calculate total price

   // Ketika modal dibuka, atur galleryImages dengan data yang benar
   const handleOpenModalImageGallery = () => {
        if (experience_medias.length > 0) {
            const images = experience_medias.map((media: any) => media.media.path);
            console.log('Setting galleryImages:', images); // Tambahkan log ini
            setGalleryImages(images);
            setIsModalOpen(true);
        } else {
            console.error("No images found for this experience.");
        }
    };


    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const renderSection1 = () => (
        <div className="listingSection__wrap !space-y-6">
            <div className="flex justify-between items-center">
            <Badge 
                color={getCategoryColor(experience.experience_type_id)} 
                name={experience.experience_type_id === 1 ? 'Activities' : 'Tour'} 
            />       
                <LikeSaveBtns 
                  slug={slug || ""} 
                  experienceId={experience.id || ""}
                />
                </div>
            <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {title}
            </h2>
            <div className="flex items-center space-x-4">
                <StartRating point={rating_average} />
                <span>•</span>
                <span>
                    <i className="las la-map-marker-alt"></i>
                    <span className="ml-1">{location}</span>
                </span>
            </div>
            <div className="flex items-center">
                <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
                    Hosted by{" "}
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium">
                        {host}
                    </span>
                </span>
            </div>
            <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />
            <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                    <i className="las la-clock text-2xl"></i>
                    <span className="">
                        {experience.duration_hour % 1 === 0 
                            ? `${Math.floor(experience.duration_hour)} hours` 
                            : `${Math.round((experience.duration_hour % 1) * 100)} minutes`}
                        </span>                
                        </div>
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                    <i className="las la-user-friends text-2xl"></i>
                    <span className="">Up to {max_participant_count} people</span>
                </div>
                <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
                    <i className="las la-language text-2xl"></i>
                    <span className="">{experience.language}</span>
                </div>
            </div>
        </div>
    );

    const renderSection2 = () => (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">Experiences descriptions</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300">
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        </div>
    );

    const renderSection3 = () => (
        <div className="listingSection__wrap">
            <div>
            <div className="prose sm:prose">
            <h4 className="text-lg font-semibold">Includes</h4>
                    <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
                        {includes.split(",").map((item: string, index: number) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>        
        </div>
    );

    const renderSection6 = () => (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">Reviews ({Array.isArray(reviews) ? reviews.length : 0} reviews)</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="space-y-5">
                <FiveStartIconForRate
                    iconClass="w-6 h-6"
                    className="space-x-0.5"
                    rating={newReview.rating}
                    onRating={(rating) => setNewReview((prev) => ({ ...prev, rating }))}
                />
                <div className="relative">
                    <Input
                        value={newReview.message}
                        onChange={(e) => setNewReview(prev => ({ ...prev, message: e.target.value }))}
                        fontClass=""
                        sizeClass="h-16 px-4 py-3"
                        rounded="rounded-3xl"
                        placeholder="Share your experience..."
                    />
                    <ButtonCircle
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                        size="w-12 h-12"
                        onClick={handleReviewSubmit}
                    >
                        <ArrowRightIcon className="w-5 h-5" />
                    </ButtonCircle>
                </div>
            </div>
            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
                {reviews.slice(0, displayLimit).map((review, index) => (
                    <CommentListing key={index} className="py-8" review={review} />
                ))}
                {reviews.length > displayLimit && (
                    <div className="pt-8">
                        <ButtonSecondary onClick={handleLoadMoreReviews}>View more reviews</ButtonSecondary>
                    </div>
                )}
            </div>
        </div>
    );

    const renderSection7 = () => (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">Location</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {location}
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                <div className="rounded-xl overflow-hidden z-0">
                    <iframe
                        title="x"
                        width="100%"
                        height="100%"
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${location}`}
                    ></iframe>
                </div>
            </div>
        </div>
    );

    const renderSection8 = () => (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">Things to know</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
                <h4 className="text-lg font-semibold">Cancellation policy</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    {cancellation_policy}
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
                <h4 className="text-lg font-semibold">Guest requirements</h4>
                <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                    Please: {requirement}
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div>
                <h4 className="text-lg font-semibold">What to bring</h4>
                <div className="prose sm:prose">
                    <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
                        {what_to_bring.split("__").map((item: string, index: number) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );

    const renderSidebar = () => (
        <div className="listingSectionSidebar__wrap shadow-xl p-4 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
                Currency
              </label>
              <select
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                value={currency}
                onChange={(e) => setCurrency(e.target.value as "IDR" | "USD" | "EUR")}
              >
                <option value="IDR">IDR - Indonesian Rupiah</option>
                <option value="USD">USD - US Dollar</option>
                <option value="EUR">EUR - Euro</option>
              </select>
            </div>
          </div>
      
          {/* Render total harga berdasarkan guests */}
          <span className="text-3xl font-semibold">
            {currency}{" "}
            {typeof convertedPrice === "number" ? convertedPrice.toFixed(2) : convertedPrice}
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              {experience_type_id === 2 ? "/group" : `/person`}
            </span>
          </span>
      
          <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl mt-4">
            <StayDatesRangeInput className="flex-1 z-[11]" onDateChange={handleDateChange} />
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="flex items-center mt-4 space-x-4">
                {/* Icon di kiri */}
                <div className="ms-2 flex-shrink-0 flex items-center justify-center w-10 h-10 bg-neutral-100 rounded-full" >
                    <ClockIcon className="ms-3w-6 h-6 text-neutral-500 dark:text-neutral-400" />
                </div>

                {/* Dropdown dan teks di kanan */}
                <div className="flex-1">
                <label 
                    htmlFor="time" 
                    className="text-sm font-semibold text-neutral-700 dark:text-neutral-300"
                >
                    Select Time
                </label>
                
                {/* Dropdown untuk memilih waktu */}
                <select
                    id="time"
                    className="w-full border border-neutral-200 dark:border-neutral-700 rounded-lg px-4 py-2"
                    onChange={handleTimeChange} // Handler untuk waktu
                    value={selectedTime}
                >
                    <option value="" disabled>Select a time</option>
                    {experience.times_available_string.split("__").map((time: string) => (
                    <option key={time} value={time}>{time}</option>
                    ))}
                </select>

                {/* Keterangan di bawah dropdown */}
                <small className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    Select the time you want to come or the pickup time.
                </small>
                </div>
                </div>

            
      
            {/* Menggunakan GuestsInput untuk tamu dewasa dan anak-anak */}
            <GuestsInput
            className="flex-1"
            onGuestsChange={(totalGuests, adultGuests, childGuests) => {
                setGuests(totalGuests);
                setAdultGuests(adultGuests);
                setChildGuests(childGuests);
            }}
            maxParticipantCount={experience.max_participant_count}
            hasChildPrice={experience.has_child_price} // Tampilkan input anak-anak jika has_child_price true
            childAgeRange={{
                min: experience.child_age_range_min,
                max: experience.child_age_range_max
            }} // Berikan rentang umur anak-anak dari data experience
            adultAgeRange={{
                min: experience.adult_age_range_min,
                max: experience.adult_age_range_max
            }} // Berikan rentang umur dewasa dari data experience
            />
          </form>

          
      
          <div className="flex justify-between font-semibold mt-4">
            <span>Total</span>
            <span>
              {currency} {typeof totalPrice === "number" ? totalPrice.toFixed(2) : totalPrice}
            </span>
          </div>
          <ButtonPrimary
  onClick={() => {
    if (!selectedDate) {
      alert("Please select a date before proceeding.");
      return;
    }

    // Hitung harga per orang
    const adultPrice = experience.price; // Asumsi experience.price adalah harga per dewasa
    const childPrice = experience.has_child_price ? experience.child_price : experience.price;

    const checkoutData = {
      experienceId: experience.id,
      experiencePickup: experience.experience_pickups,
      experienceTitle: experience.title,
      price: totalPrice, // total calculated price
      adultPrice, // harga per dewasa
      childPrice, // harga per anak
      adultGuests, // jumlah tamu dewasa
      childGuests, // jumlah tamu anak-anak
      time: selectedTime,
      infantGuests, // jumlah tamu bayi
      date: selectedDate,
      mainImage: experience_medias[0]?.media?.path || "",
      experienceLocation: experience.location,
      currency, // mata uang yang dipilih
      has_child_price: experience.has_child_price, // flag untuk harga anak
    };

    sessionStorage.setItem("checkoutData", JSON.stringify(checkoutData));
    navigate(`/checkout`);
  }}
>
  Reserve
</ButtonPrimary>


        </div>
      );
      
    
  
    return (
        
        <div className={`nc-ListingExperiencesDetailPage `}>
            <header className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
                <div
                    className="col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                    
                >
            <Helmet>
                <title>{experience.title} - Bali Tours and Activities</title>
                <meta name="description" content={experience.description} />
                <link rel="canonical" href={`https://baliguided.com/experiences/details/${slug}`} />
            </Helmet>

        <GallerySlider
            uniqueID={`ExperiencesGallery_${id}`}
            ratioClass="aspect-w-4 aspect-h-3"
            galleryImgs={experience_medias.map((media: any) => media?.media?.path)}
            href={`/experiences/details/${slug}`}
        />
        <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
    </div>
    {experience_medias.slice(1, 4).map((media: any, index: number) => (
        <div
            key={index}
            className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 2 ? "block" : ""
            }`}
        >
            <div className="aspect-w-4 aspect-h-3">
                <img
                    alt="photos"
                    className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={media?.media?.path || ""}
                    sizes="400px"
                />
            </div>
            <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
            />
        </div>
    ))}
    <div
        className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
        onClick={handleOpenModalImageGallery}
    >
        <Squares2X2Icon className="h-5 w-5" />
        <span className="ml-2 text-neutral-800 text-sm font-medium">
            Show all photos
        </span>
    </div>
</div>

            </header>

            <main className="relative z-10 mt-11 flex flex-col lg:flex-row ">
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
                    {renderSection1()}
                    {renderSection2()}
                    {renderSection3()}
                    {renderSection6()}
                    {renderSection7()}
                    {renderSection8()}
                </div>

                <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                    <div className="sticky top-28">{renderSidebar()}</div>
                </div>
            </main>

            {/* Modal for image gallery */}
            <ImageGalleryModal
              isOpen={isModalOpen}
              onRequestClose={handleCloseModal}
              images={galleryImages} // Pastikan galleryImages diteruskan di sini
          />
        </div>
    );
};

export default function ListingExperiencesDetailPage() {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [guests, setGuests] = useState<number>(1);
    const [experience, setExperience] = useState<any | null>(null);
    const { slug } = useParams<{ slug: string }>();

    // Fetch the experience data when the component mounts
    useEffect(() => {
        const fetchExperience = async () => {
            try {
                const response = await experienceService.getExperienceBySlug(slug);
                setExperience(response);
            } catch (error) {
                console.error("Error fetching experience data:", error);
            }
        };

        fetchExperience();
    }, [slug]);

    if (!experience) {
        return <div>Loading...</div>;
    }

    // Function to convert price based on currency, but return as number
    const convertPrice = (price: number) => {
        return price;
    };

    // Calculate total price for guests
    const calculatePrice = () => {
        const adultPrice = experience.price;
        const childPrice = experience.has_child_price ? experience.child_price : experience.price;

        // Jika ada harga anak, hitung dengan tamu dewasa dan anak-anak, jika tidak, gunakan harga per orang biasa
        return experience.has_child_price
            ? convertPrice(adultPrice * guests + childPrice * guests) // Perhitungkan childPrice jika ada
            : convertPrice(adultPrice * guests);
    };

    const convertedPrice = calculatePrice();

    return (
        <DetailPagetLayout
            experience={experience}
            currency="IDR"
            guests={guests}
            convertedPrice={convertedPrice}
            selectedDate={selectedDate}
            onDateChange={setSelectedDate}
            onGuestsChange={setGuests}
        >
            <ListingExperiencesDetail />
        </DetailPagetLayout>
    );
}