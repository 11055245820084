import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ExperiencesCard from "components/CardTour/ExperiencesCard";
import { Helmet } from "react-helmet";
import { ExperiencesDataType } from "data/types";
import experienceService from "services/experienceService";

const ListingExperienceCategoryPage = () => {
  const [experiences, setExperiences] = useState<ExperiencesDataType[]>([]);
  const [loading, setLoading] = useState(true);
  const { categoryName } = useParams();

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        if (categoryName) {
          const response = await experienceService.getExperiencesByCategory(categoryName);
          setExperiences(response.payload);
        }
      } catch (error) {
        console.error("Error fetching experiences:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExperiences();
  }, [categoryName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-16 px-4 lg:px-8">
      <Helmet>
        <title>Best Bali {categoryName} Activity and Things To Do For Travelers - Bali Guided</title>
        <meta 
          name="description" 
          content={`Discover the best ${categoryName} activities and things to do in Bali. Book your ${categoryName} experiences with Bali Guided for unforgettable adventures.`}
        />
        <meta 
          name="keywords" 
          content={`Bali ${categoryName}, ${categoryName} activities, things to do in Bali, ${categoryName} tours, ${categoryName} experiences, Bali activities`}
        />
      </Helmet>

      <h1 className="text-3xl font-semibold mb-4 capitalize">
        {categoryName} Activity, Things To Do and Experiences in Bali
      </h1>

      <p className="text-gray-600 mb-8">
        Explore the best {categoryName} activities and experiences in Bali. Whether you're seeking thrilling adventures or memorable moments, discover our curated selection of {categoryName} experiences for your perfect Bali getaway.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {experiences.map((experience) => (
          <ExperiencesCard key={experience.id} data={experience} />
        ))}
      </div>

      {experiences.length === 0 && (
        <div className="text-center py-10">
          <p>No experiences found in this category.</p>
        </div>
      )}
    </div>
  );
};

export default ListingExperienceCategoryPage;
