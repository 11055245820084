import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import bookingService from '../../services/bookingService.js';
import { format } from 'date-fns';
import { Tab } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import airportTransferService from 'services/airporttransferService';
import experienceService from 'services/experienceService.js';
import hotelTransferService from 'services/hoteltransferService';

interface Booking {
  id: string;
  booking_type: string;
  date: string;
  time: string;
  details: string;
  reference_id: string;
  status: string;
  pickup_place?: string;
  dropoff_place?: string;
  experience_title?: string;
  experience_location?: string;
  name?: string;
  person?: number;
  total_price?: number;
  currency_choosed?: string;
}

const MyBookings: React.FC = () => {
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [detailedBookings, setDetailedBookings] = useState<any[]>([]);

  useEffect(() => {
    // Langsung redirect jika tidak authenticated
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const fetchBookings = async () => {
        try {
            setIsLoading(true);
            const response = await bookingService.getUserBookings();
            console.log('Initial bookings response:', response);
            
            if (response && response.payload) {
                const bookingsWithDetails = await Promise.all(
                    response.payload.map(async (booking: any) => {
                        try {
                            let details;
                            switch (booking.booking_type) {
                                case 'experience':
                                    try {
                                        const cartDetails = await experienceService.getBookingById(booking.reference_id);
                                        console.log('Cart details:', cartDetails);
                                        
                                        const parsedDetails = typeof booking.details === 'string' 
                                            ? JSON.parse(booking.details) 
                                            : booking.details;

                                        details = {
                                            ...parsedDetails,
                                            ...cartDetails,
                                            title: cartDetails?.experience_title,
                                            location: cartDetails?.experience_location,
                                            name: cartDetails?.name,
                                            date: cartDetails?.date,
                                            time: cartDetails?.time,
                                            person: cartDetails?.person,
                                            total_price: cartDetails?.total_price,
                                            status: "Confirmed"
                                        };
                                    } catch (error) {
                                        console.error('Error fetching experience details:', error);
                                        details = { status: "Confirmed" };
                                    }
                                    return { ...booking, details, status: "Confirmed" };
                                    
                                case 'airport_transfer':
                                    details = await airportTransferService.getBookingById(booking.reference_id);
                                    return { ...booking, details, status: "Confirmed" };
                                    
                                case 'hotel_transfer':
                                    details = await hotelTransferService.getBookingById(booking.reference_id);
                                    return { 
                                        ...booking, 
                                        details: {
                                            ...details,
                                            pickup: details?.pickup || booking.pickup_place,
                                            dropoff: details?.dropoff || booking.dropoff_place,
                                            name: details?.name,
                                            date: details?.date,
                                            time: details?.time,
                                            pax: details?.pax || details?.guests,
                                            price: details?.price || booking.total_price,
                                            status: "Confirmed"
                                        },
                                        status: "Confirmed"
                                    };
                                
                                default:
                                    return { ...booking, status: "Confirmed" };
                            }
                        } catch (error) {
                            console.error(`Error fetching details for booking ${booking.reference_id}:`, error);
                            return { ...booking, details: {}, status: "Confirmed" };
                        }
                    })
                );
                
                console.log('Final bookings with details:', bookingsWithDetails);
                setDetailedBookings(bookingsWithDetails);
            }
        } catch (error) {
            console.error('Failed to fetch bookings:', error);
            setDetailedBookings([]);
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        fetchBookings();
    }
  }, [isAuthenticated, navigate]);

  // Jika tidak authenticated, tidak perlu render apapun
  if (!isAuthenticated) {
    return null;
  }

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const renderBookingCard = (booking: any) => {
    const details = booking.details || {};
    
    const formatDate = (dateString: string) => {
        if (!dateString) return 'N/A';
        try {
            return format(new Date(dateString), 'dd MMM yyyy');
        } catch (error) {
            return 'N/A';
        }
    };

    return (
        <div key={booking.id} className="border rounded-lg p-6 bg-white shadow-sm mb-4">
            <div className="flex flex-col sm:flex-row justify-between items-start mb-4">
                <div className="mb-3 sm:mb-0">
                    <h3 className="text-lg font-semibold mb-2">
                        {booking.booking_type === 'experience' 
                            ? details.experienceTitle || 'Experience Booking'
                            : booking.booking_type === 'hotel_transfer'
                            ? `Hotel Transfer: ${details.pickup || ''} → ${details.dropoff || ''}`
                            : `Airport Transfer: ${details.pickup || ''} → ${details.dropoff || ''}`
                        }
                    </h3>
                    <p className="text-gray-600 text-sm mb-1">Booking Code: {booking.reference_id}</p>
                    <p className="text-gray-600 text-sm">Guest Name: {details.name || 'N/A'}</p>
                </div>
                <span className="px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800 self-start">
                    Confirmed
                </span>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-3">
                <div>
                    <p className="text-sm text-gray-500">Date</p>
                    <p className="font-medium">{formatDate(details.date)}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-500">Time</p>
                    <p className="font-medium">{details.time || 'N/A'}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-500">Number of Guests</p>
                    <p className="font-medium">{details.pax || details.guests || details.person || 'N/A'}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-500">Total Price</p>
                    <p className="font-medium">
                        {booking.currency_choosed || 'IDR'} {details.price?.toLocaleString() || booking.total_price?.toLocaleString()}
                    </p>
                </div>
            </div>

            {(booking.booking_type === 'airport_transfer' || booking.booking_type === 'hotel_transfer') && (
                <div className="mt-4 border-t pt-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                            <p className="text-sm text-gray-500">Pickup Location</p>
                            <p className="font-medium">{details.pickup || details.pickup_place || 'N/A'}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Dropoff Location</p>
                            <p className="font-medium">{details.dropoff || details.dropoff_place || 'N/A'}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">My Bookings</h1>
      
      <Tab.Group>
        <Tab.List className="flex overflow-x-auto space-x-4 mb-8 border-b">
          <Tab className={({ selected }) => `
            px-4 py-2 text-sm font-medium whitespace-nowrap
            ${selected 
              ? 'text-primary border-b-2 border-primary' 
              : 'text-gray-500 hover:text-gray-700'
            }
          `}>
            All Bookings
          </Tab>
          <Tab className={({ selected }) => `
            px-4 py-2 text-sm font-medium whitespace-nowrap
            ${selected 
              ? 'text-primary border-b-2 border-primary' 
              : 'text-gray-500 hover:text-gray-700'
            }
          `}>
            Experiences
          </Tab>
          <Tab className={({ selected }) => `
            px-4 py-2 text-sm font-medium whitespace-nowrap
            ${selected 
              ? 'text-primary border-b-2 border-primary' 
              : 'text-gray-500 hover:text-gray-700'
            }
          `}>
            Airport Transfers
          </Tab>
          <Tab className={({ selected }) => `
            px-4 py-2 text-sm font-medium whitespace-nowrap
            ${selected 
              ? 'text-primary border-b-2 border-primary' 
              : 'text-gray-500 hover:text-gray-700'
            }
          `}>
            Hotel Transfers
          </Tab>
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            {detailedBookings.length > 0 ? (
              detailedBookings.map(renderBookingCard)
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-500">No bookings found</p>
              </div>
            )}
          </Tab.Panel>

          <Tab.Panel>
            {detailedBookings
              .filter(booking => booking.booking_type === 'experience')
              .map(renderBookingCard)}
          </Tab.Panel>

          <Tab.Panel>
            {detailedBookings
              .filter(booking => booking.booking_type === 'airport_transfer')
              .map(renderBookingCard)}
          </Tab.Panel>

          <Tab.Panel>
            {detailedBookings
              .filter(booking => booking.booking_type === 'hotel_transfer')
              .map(renderBookingCard)}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default MyBookings; 