import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import Avatar from "shared/Avatar/Avatar";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import userService from "services/userService";
import { ShoppingCartIcon } from '@heroicons/react/24/outline';

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const authUser = localStorage.getItem("authUser");
      if (authUser) {
        setIsLoggedIn(true);
        try {
          const user = await userService.getMe();
          setProfileImage(user?.profile_picture?.path ?? null);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authUser");
    window.location.reload();
  };

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            {isLoggedIn ? (
              <>
                <SearchDropdown />
                <div className="px-1" />
                <Link to="/my-bookings" className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100 dark:bg-neutral-800">
                  <ShoppingCartIcon className="h-6 w-6 text-neutral-700 dark:text-neutral-300" />
                </Link>
                <div className="px-1" />
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100 dark:bg-neutral-800"
                >
                  {profileImage ? (
                    <Avatar sizeClass="w-full h-full" imgUrl={profileImage} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-neutral-700 dark:text-neutral-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </>
            ) : (
              <ButtonPrimary href="/login">Login</ButtonPrimary>
            )}
          </div>

          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            {isLoggedIn ? (
              <>
                <Link to="/my-bookings" className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100 dark:bg-neutral-800">
                  <ShoppingCartIcon className="h-6 w-6 text-neutral-700 dark:text-neutral-300" />
                </Link>
                <div className="px-1" />
                <div
                  onClick={() => setShowDropdown(!showDropdown)}
                  className="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-100 dark:bg-neutral-800"
                >
                  {profileImage ? (
                    <Avatar sizeClass="w-full h-full" imgUrl={profileImage} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-neutral-700 dark:text-neutral-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              </>
            ) : (
              <ButtonPrimary href="/login">Login</ButtonPrimary>
            )}
          </div>
        </div>
      </div>

      {showDropdown && (
        <div className="absolute top-16 right-0 z-10 bg-white dark:bg-neutral-800 rounded-md shadow-lg w-48">
          <Link
            to="/account"
            className="block px-4 py-2 text-neutral-900 dark:text-neutral-100 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            Account
          </Link>
          <Link
            to="/my-bookings"
            className="block px-4 py-2 text-neutral-900 dark:text-neutral-100 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            My Bookings
          </Link>
          <Link
            to="/account-savelists"
            className="block px-4 py-2 text-neutral-900 dark:text-neutral-100 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            My Wishlists
          </Link>
          <Link
            to="/reviews"
            className="block px-4 py-2 text-neutral-900 dark:text-neutral-100 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            My Reviews
          </Link>
          <button
            onClick={handleLogout}
            className="block w-full text-left px-4 py-2 text-red-900 dark:text-red-300 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default MainNav1;
