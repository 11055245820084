import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ExperiencesCard from "components/CardTour/ExperiencesCard";
import { Helmet } from "react-helmet";
import { ExperiencesDataType } from "data/types";
import experienceService from "services/experienceService.js";

const ListingExperiencePlacePage = () => {
  const [experiences, setExperiences] = useState<ExperiencesDataType[]>([]);
  const [loading, setLoading] = useState(true);
  const { placeName } = useParams();

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        if (placeName) {
          const response = await experienceService.getExperiencesByPlace(placeName);
          setExperiences(response.payload);
        }
      } catch (error) {
        console.error("Error fetching experiences:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExperiences();
  }, [placeName]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mx-auto py-16 px-4 lg:px-8">
      <Helmet>
        <title>Best Bali {placeName} Activity and Things To Do For Travelers - Bali Guided</title>
        <meta 
          name="description" 
          content={`Discover the best activities and things to do in ${placeName}, Bali. Book your ${placeName} experiences with Bali Guided for unforgettable adventures.`}
        />
        <meta 
          name="keywords" 
          content={`Bali ${placeName}, ${placeName} activities, things to do in ${placeName}, ${placeName} tours, ${placeName} experiences, Bali activities`}
        />
      </Helmet>

      <h1 className="text-3xl font-semibold mb-4 capitalize">
        {placeName} Activity, Things To Do and Experiences 
      </h1>

      <p className="text-gray-600 mb-8">
        Discover the best activities and unforgettable experiences in {placeName}, Bali. From cultural tours to adventure activities, find and book the perfect {placeName} experience for your Bali journey.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {experiences.map((experience) => (
          <ExperiencesCard key={experience.id} data={experience} />
        ))}
      </div>

      {experiences.length === 0 && (
        <div className="text-center py-10">
          <p>No experiences found in this place.</p>
        </div>
      )}
    </div>
  );
};

export default ListingExperiencePlacePage; 