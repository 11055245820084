import React, { FC, useState, useRef, Fragment } from "react";
import { Tab } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import airportTransferService from "services/airporttransferService";
import bookingService from "services/bookingService.js";
import { useAuth } from '../../hooks/useAuth';


const libraries: ("places")[] = ["places"];

const AirportTransferCheckout: FC = () => {
  const location = useLocation();
  const [pickupAddress, setPickupAddress] = useState("");
  const { pickup, dropoff, date, time, vehicleType, priceInIDR, currency, guests, distance, seat, note, emailOrPhone } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);


  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [formValid, setFormValid] = useState(false);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  const pickupRef = useRef<google.maps.places.SearchBox | null>(null);
  const [differentHotel, setDifferentHotel] = useState(false);
  const [dropoffAddress, setDropoffAddress] = useState("");
  const dropoffRef = useRef<google.maps.places.SearchBox | null>(null);

  const formattedDate = date ? new Date(date).toLocaleDateString() : "Invalid date";
  const navigate = useNavigate();

  const { isAuthenticated, userId } = useAuth();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateWhatsapp = (whatsapp: string) => {
    return whatsapp.startsWith("+") && whatsapp.length > 8;
  };

  const checkFormValidity = () => {
    if (
      validateEmail(email) &&
      validateWhatsapp(whatsapp) &&
      name.trim() !== "" &&
      flightNumber.trim() !== ""
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  const generateBookingId = () => {
    // Menghasilkan nomor acak unik berdasarkan timestamp
    const randomNumber = Date.now().toString(); // Bisa menggunakan UUID atau timestamp
    
    // Menggabungkan dengan prefix
    const bookingId = `BALIGUIDEDAPT_${randomNumber}`;
  
    return bookingId;
  };
  

  const handleConfirm = async () => {
    setIsLoading(true);
    const bookingId = generateBookingId();

    const bookingData = {
      bookingDetails: {
        pickup,
        dropoff,
        date: new Date(date),
        time,
        pax: guests.toString(),
        seat: seat || 'None',
        name,
        note: note || 'No special requests',
        email,
        phone: whatsapp,
        price: priceInIDR || 0,
        flight_number: flightNumber || '',
        booking_id: bookingId,
        choosed_car: vehicleType,
        distance: parseFloat(distance) || 0,
        status: "Not Confirmed"
      },
      userEmail: email
    };

    // Data untuk tabel bookings
    const generalBookingData = {
      user_id: isAuthenticated ? userId : "fd272505-bc2e-4c4a-836f-8d42f6f03e47", // Guest ID jika tidak login
      booking_type: "airport_transfer",
      reference_id: bookingId,
      total_price: priceInIDR || 0,
      status: "pending",
      details: JSON.stringify(bookingData.bookingDetails) // Convert ke string karena details adalah JSONB
    };

    try {
      // Simpan ke tabel airport_transfer_bookings
      const response = await airportTransferService.createBooking(bookingData.bookingDetails);
      console.log('Airport transfer booking created:', response);

      // Simpan ke tabel bookings
      const bookingResponse = await bookingService.createBooking(generalBookingData);
      console.log('General booking created:', bookingResponse);

      navigate('/airport-transfer/pay-done', { 
        state: { 
          ...bookingData.bookingDetails, 
          priceInIDR 
        } 
      });
    } catch (error) {
      console.error('Booking creation failed:', (error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };


const renderDetails = () => {
    let vehicleImage = "";

    if (vehicleType === "Standard") {
      vehicleImage = "/images/standard-car.png";
    } else if (vehicleType === "People carrier") {
      vehicleImage = "/images/people-carrier.png";
    } else if (vehicleType === "Large people carrier") {
      vehicleImage = "/images/large-people-carrier.png";
    } else if (vehicleType === "Executive") {
      vehicleImage = "/images/executive-car.png";
    } else if (vehicleType === "Van") {
      vehicleImage = "https://cdni.autocarindia.com/Utils/ImageResizer.ashx?n=https://cdni.autocarindia.com/ExtraImages/20210212120952_Hiace_front.jpg&w=700&c=1";
    }

    return (
      <div className="w-full flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-6 p-6">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={vehicleImage} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {`${pickup} to ${dropoff}`}
              </span>
              <span className="text-base font-medium mt-1 block">
                {vehicleType} - {distance} 
              </span>
            </div>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              {guests} guests
            </span>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              Date: {formattedDate}, Time: {time}
            </span>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex justify-between text-neutral-600 dark:text-neutral-300">
            <span>{currency} {priceInIDR.toLocaleString("id-ID")}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{currency} {priceInIDR.toLocaleString("id-ID")}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div>
          {renderDetails()}
        </div>

        {/* Section untuk Traveler Data */}
        <div>
          <h3 className="text-2xl font-semibold">Traveler Data</h3>
          <div className="mt-6 space-y-4">
            <div className="space-y-1">
              <Label>Flight Number</Label>
              <Input 
                type="text" 
                placeholder="Enter your flight number, example: EK 368"
                value={flightNumber}
                onChange={(e) => {
                  setFlightNumber(e.target.value);
                  checkFormValidity();
                }}
              />
            </div>
            <div className="space-y-1">
              <Label>Email</Label>
              <Input 
                type="email" 
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  checkFormValidity();
                }}
              />
              {!validateEmail(email) && email !== "" && (
                <span className="text-red-500 text-sm">Invalid email address</span>
              )}
            </div>
            <div className="space-y-1">
              <Label>Name</Label>
              <Input 
                type="text" 
                placeholder="Enter your name for name sign"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  checkFormValidity();
                }}
              />
            </div>
            <div className="space-y-1">
              <Label>WhatsApp Number</Label>
              <Input 
                type="text" 
                placeholder="Enter your WhatsApp number"
                value={whatsapp}
                onChange={(e) => {
                  setWhatsapp(e.target.value);
                  checkFormValidity();
                }}
              />
              {!validateWhatsapp(whatsapp) && whatsapp !== "" && (
                <span className="text-red-500 text-sm">Invalid WhatsApp number. Include the country code (e.g., +62).</span>
              )}
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Meeting Point</h3>
          <div className="mt-6 space-y-4">
            <div className="space-y-1">
              <Label>Details Meeting Point</Label>
              <p className="text-sm text-neutral-700 dark:text-neutral-300">
                The Driver will be in the International or Domestic Arrival based on the flight holding your name. Please find your name and we will also send you a Whatsapp message. Thank you and see you!
              </p>
              <img
                src="https://balikit.com/wp-content/uploads/2023/05/Bali-Airport-Transfer-Taxi-Exit-and-Prices-Guide.jpg"
                alt="Bali Airport Transfer Meeting Point"
                className="mt-4 w-full h-auto rounded-lg"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Cash
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-200 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                {/* Cash */}
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <div className="flex justify-between text-lg font-semibold">
                      <span>Total</span>
                      <span>{currency} {priceInIDR}</span>
                    </div>
                  </div>

                  <div className="space-y-1">
                    <span className="text-sm text-neutral-500 block">
                      You can pay in any currency equivalent to the total amount shown above.
                      Note: Cash payment need to confirmation in Whatsapp before the arrival, we need to check it's really a traveler not a bot or fake human.
                    </span>
                  </div>
                </Tab.Panel>

                {/* Paypal */}
                <Tab.Panel className="relative space-y-5">
                  {/* Overlay untuk Maintenance dengan Rounded Corners */}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 rounded-lg">
                    <span className="text-white text-lg font-semibold">Maintenance</span>
                  </div>
                  
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" placeholder="Enter your email" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" placeholder="Enter your password" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="Write a few sentences about yourself..." disabled />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>

                {/* Credit Card */}
                <Tab.Panel className="relative space-y-5">
                  {/* Overlay untuk Maintenance dengan Rounded Corners */}
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 rounded-lg">
                    <span className="text-white text-lg font-semibold">Maintenance</span>
                  </div>
                  
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input placeholder="Enter your card number" disabled />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input placeholder="Enter card holder name" disabled />
                  </div>
                  <div className="flex space-x-5">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="text" placeholder="MM/YY" disabled />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input placeholder="Enter CVC" disabled />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Message for author </Label>
                    <Textarea placeholder="Write a few sentences about yourself..." disabled />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary onClick={handleConfirm} disabled={!formValid || isLoading}>
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    {/* Animasi loading spinner */}
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                    Processing...
                  </div>
                ) : (
                  'Confirm'
                )}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-AirportTransferCheckout">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col space-y-8">
        <div className="w-full">
          {renderMain()}
        </div>
      </main>
    </div>
  );
};

export default AirportTransferCheckout;
