import React, { useState } from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

interface Category {
  id: number;
  name: string;
  icon: string;
}

const CATEGORIES: Category[] = [
  { id: 1, name: 'Nature', icon: '/images/categories/nature.jpg' },
  { id: 2, name: 'Adventure', icon: '/images/categories/adventure.jpg' },
  { id: 3, name: 'Family', icon: '/images/categories/family.jpg' },
  { id: 4, name: 'Water', icon: '/images/categories/water.jpg' },
  { id: 5, name: 'Food', icon: '/images/categories/food.jpg' },
  { id: 6, name: 'Beach', icon: '/images/categories/beach.jpg' },
  { id: 7, name: 'Sightseeing', icon: '/images/categories/sightseeing.jpg' },
  { id: 8, name: 'Nightlife', icon: '/images/categories/nightlife.jpg' },
  { id: 9, name: 'Cultural', icon: '/images/categories/cultural.jpg' },
  { id: 10, name: 'Shopping', icon: '/images/categories/shopping.jpg' },
  { id: 11, name: 'Wildlife', icon: '/images/categories/wildlife.jpg' },
];

const SectionByCategories = () => {
  const navigate = useNavigate();
  const [categories] = useState<Category[]>(CATEGORIES);
  const scrollContainer = React.useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainer.current) {
      const scrollAmount = 300;
      scrollContainer.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handleCategoryClick = (categoryName: string) => {
    navigate(`/experiences/categories/${categoryName.toLowerCase()}`);
  };

  return (
    <div className="relative py-8">
      <h2 className="text-2xl font-semibold mb-6">Browse by Category</h2>
      
      <div className="relative group">
        <button 
          onClick={() => scroll('left')}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 w-10 h-10 bg-white rounded-full shadow-md flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </button>

        <div 
          ref={scrollContainer}
          className="flex overflow-x-auto gap-4 pb-4 scrollbar-hide"
        >
          {categories.map((category) => (
            <div 
              key={category.id}
              className="flex-none w-48 cursor-pointer hover:transform hover:scale-105 transition-transform duration-200"
              onClick={() => handleCategoryClick(category.name)}
            >
              <div className="flex flex-col items-center p-4 rounded-xl bg-white shadow-sm">
                <div className="w-full h-32 mb-3">
                  <img 
                    src={category.icon} 
                    alt={category.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <h3 className="text-center font-medium">
                  {category.name}
                </h3>
              </div>
            </div>
          ))}
        </div>

        <button 
          onClick={() => scroll('right')}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 w-10 h-10 bg-white rounded-full shadow-md flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default SectionByCategories; 