import httpClient from '../utils/httpClient';

const wishlistService = {
  addToWishlist: async (experienceId) => {
    try {
      const response = await httpClient.post('/wishlists', { experienceId });
      return response.data;
    } catch (error) {
      console.error('Add to wishlist error:', error);
      throw error;
    }
  },

  removeFromWishlist: async (experienceId) => {
    try {
      const response = await httpClient.del(`/wishlists/${experienceId}`);
      return response.data;
    } catch (error) {
      console.error('Remove from wishlist error:', error);
      throw error;
    }
  },

  getUserWishlists: async () => {
    try {
      const response = await httpClient.get('/wishlists/my-wishlists');
      return response.data;
    } catch (error) {
      console.error('Get wishlists error:', error);
      throw error;
    }
  },

  checkWishlist: async (experienceId) => {
    try {
      const response = await httpClient.get(`/wishlists/check/${experienceId}`);
      return response.data;
    } catch (error) {
      console.error('Check wishlist error:', error);
      throw error;
    }
  },
};

export default wishlistService; 