import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import authService from "services/authService";
import Oauth from "helpers";
import { Alert } from "shared/Peringatan/Peringatan";
 
export interface PageLoginProps {
  className?: string;
}
 
const loginSocials = Oauth.config;
 
const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
 
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [typeMessage, setTypeMessage] = useState<
    "success" | "error" | "default" | "warning" | "info"
  >("default");
 
  const handleLogin = async () => {
    setLoading(true);
    try {
      await authService.login({ email, password });      
      window.location.href = "/";
      setLoading(false);
    } catch (error: any) {
      setTypeMessage("error");
      setMessage(error.message);
      setLoading(false);
    }
  };
 
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login BaliGuided</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <p className="my-10 flex items-center leading-[115%] md:text-1xl md:leading-[115%] font-regular text-neutral-900 dark:text-neutral-100 justify-center">
          We can also book without login, We will send the bookings detail via E-mail and contact you in Whatsapp. But you can see more your bookings with login. 
        </p>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              item.isUsed && <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          {message && (
            <Alert type={typeMessage} close={() => setMessage("")}>
              {message}
            </Alert>
          )}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                required
                value={email}
                onInput={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input
                type="password"
                className="mt-1"
                required
                value={password}
                onInput={(e) => {
                  setPassword(e.currentTarget.value);
                }}
              />
            </label>
            <ButtonPrimary type="button" onClick={handleLogin} disabled={loading}>
              {
                // create spinner animation while loading
                loading ? <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
                </div> : "Login"
              }
            </ButtonPrimary>
          </form>
 
          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};
 
export default PageLogin;