import React from "react";
import { Link } from "react-router-dom";
import { PaperAirplaneIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

const SectionAirportTransfer = () => {
  return (
    <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2">
        {/* Left Content */}
        <div className="p-8 lg:p-12">
          <div className="flex items-center space-x-2 mb-4">
            <PaperAirplaneIcon className="h-6 w-6 text-primary-600" />
            <h3 className="text-2xl font-semibold">Airport Transfer</h3>
          </div>
          <p className="text-gray-600 mb-6">
            Book your reliable and affordable airport transfer service in Bali. Direct to your hotel in Ubud, Kuta, Seminyak, or anywhere in Bali.
          </p>
          <ul className="space-y-3 text-gray-600 mb-8">
            <li className="flex items-center">
              <span className="mr-2">✓</span>
              Fixed price, no hidden fees
            </li>
            <li className="flex items-center">
              <span className="mr-2">✓</span>
              Professional English-speaking drivers
            </li>
            <li className="flex items-center">
              <span className="mr-2">✓</span>
              Free cancellation up to 24h before
            </li>
          </ul>
          <Link 
            to="/airport-transfer"
            className="inline-flex items-center text-primary-600 hover:text-primary-700 font-medium"
          >
            Book Now
            <ArrowRightIcon className="h-5 w-5 ml-2" />
          </Link>
        </div>

        {/* Right Image */}
        <div className="relative hidden md:block">
          <img 
            src="/images/bali-airport-transfer.jpg" 
            alt="Bali Airport Transfer" 
            className="absolute inset-0 w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionAirportTransfer; 