import React from "react";
import SectionGridFilterCard from "containers/ListingExperiencesPage/SectionGridFilterCard";
import SectionHero from "components/SectionHero/SectionHero";
import SectionSubscribe2 from "components/SectionBerlangganan/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import BgGlassmorphism from "components/BackgroundGelasBlur/BackgroundGelasBlur";
import SectionByPlaces from "components/SectionByPlaces/SectionByPlaces";
import SectionByCategories from "components/SectionByCategories/SectionByCategories";
import SectionAirportTransfer from "components/SectionAirportTransfer/SectionAirportTransfer";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <BgGlassmorphism />
 
      <div className="container relative space-y-8 mb-16 lg:space-y-10 lg:mb-20">
        {/* SECTION HERO */}
        <SectionHero className="pt-8 lg:pt-12 lg:pb-10" />

        {/* SECTION AIRPORT TRANSFER */}
        <div className="pt-2">
          <SectionAirportTransfer />
        </div>

        {/* SECTION BY PLACES */}
        <SectionByPlaces />

        {/* SECTION BY CATEGORIES */}
        <SectionByCategories />

        {/* SECTION 1 */}
        <SectionGridFilterCard       
          className="PageHome_s1"
        />

        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
}

export default PageHome;
