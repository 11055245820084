import { useState, useEffect } from 'react';

export const useAuth = () => {
  const token = localStorage.getItem('token');
  const authUser = localStorage.getItem('authUser');

  let userId = null;
  if (authUser) {
    try {
      // Token di localStorage adalah object yang berisi user data
      const parsedUser = JSON.parse(authUser);
      // Cek apakah token berisi user data
      if (typeof parsedUser === 'object') {
        userId = parsedUser.user?.id || parsedUser.id;
      } else {
        // Jika token string biasa, decode untuk ambil user id
        const decodedToken = JSON.parse(atob(token?.split('.')[1] || ''));
        userId = decodedToken.id;
      }
    } catch (error) {
      console.error('Error parsing auth user:', error);
    }
  }

  return {
    isAuthenticated: !!(token && authUser),
    userId
  };
}; 