import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const { experienceTitle, mainImage, guests, date, totalPrice, paymentMethod } = location.state || {};

  // Membuat booking code dengan format BALIGUIDEDEXP_{random number}
  const [bookingCode, setBookingCode] = useState<string>("");

  useEffect(() => {
    const generateBookingCode = () => {
      const randomNumber = Math.floor(Math.random() * 1000000); // generate random number
      return `BALIGUIDEDEXP_${randomNumber}`;
    };
    setBookingCode(generateBookingCode());
  }, []);

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Congratulation 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your booking</h3>
          <div className="div">
          <p className="text-1xl font-regular">Check your email or screenshot and show the email or screenshot to our staff.</p>
          <p className="text-1xl font-regular">If you have a question or request change time or date, please Whatsapp to this number <strong>+6282138753576</strong></p>
          <p className="text-1xl font-regular">See you!</p>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={mainImage || "https://via.placeholder.com/400"} />
              </div>
            </div>
            <div className="pt-5 sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {experienceTitle}
                </span>
              </div>
              <span className="block text-sm text-neutral-500 dark:text-neutral-400">
                {guests} guests
              </span>
              <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {new Date(date).toLocaleDateString()}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">{guests} Guests</span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {bookingCode} {/* Menampilkan Booking Code */}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {totalPrice ? totalPrice.toLocaleString() : '0'} {/* Add null check */}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {paymentMethod} {/* Payment Method */}
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/experiences">Explore more experiences</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
