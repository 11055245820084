import axios from 'axios';
import httpClient from '../utils/httpClient';


const API_BASE_URL = `${process.env.REACT_APP_BACKEND_LINK}/hotel-transfer`; // Change this to the correct API path for airport transfers

const getAuthHeaders = () => {
  const token = localStorage.getItem('token'); 
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const hotelTransferService = {
  createBooking: async (bookingData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}`, bookingData, {
        ...getAuthHeaders(),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.payload;
    } catch (error) {
      console.error('Create booking error:', error);
      throw new Error(error.message);
    }
  },

  // Fungsi untuk konfirmasi booking
  confirmBooking: async (cartData) => {
    try {
      const response = await httpClient.post('/hotel-transfer/send-booking-email', cartData);
      return response.data;
    } catch (error) {
      console.error('Confirm booking error:', error);
      throw new Error(error.message);
    }
  },

  getBookingById: async (bookingId) => {
    try {
      const response = await httpClient.get(`/hotel-transfer/bookings/${bookingId}`);
      return response.data.payload;
    } catch (error) {
      console.error('Get booking error:', error);
      throw error;
    }
  }
};

  




export default hotelTransferService;
